<template>
  <div id="app" ref="unitvplayer">
    <Reproductor></Reproductor>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Reproductor from './components/Reproductor.vue'


export default {
  name: 'App',
  components: {
    Reproductor
  },
  data() {
    return {

    }
  },
  Mount(){


  }
}
</script>

<style>
@import '../node_modules/video.js/dist/video-js.css';


body {
margin:0;
padding:0;
  overflow: hidden; /* Hide scrollbars */

}

#app {
width:100vw;
height:100vh;
margin:0;
padding:0;
  overflow: hidden; /* Hide scrollbars */
}

</style>
